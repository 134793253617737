<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="workOrdersFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    style="background-color: #eeeeee"
                >
                    <v-col cols="6" class="d-flex mb-n3">
                        <h1 class="mr-4">WORK ORDERS</h1>
                        <v-btn rounded color="primary" @click="findWO = true">
                            <v-icon class="mr-1">
                                mdi-database-search
                            </v-icon>
                            FIND
                        </v-btn>
                    </v-col>
                    <v-col cols="6" class="d-flex mb-n3">
                        <v-spacer />
                        <v-btn
                            rounded
                            color="primary"
                            @click="openWorkOrderForm"
                            class="mx-2"
                        >
                            <v-icon>
                                mdi-plus
                            </v-icon>
                            {{ $vuetify.breakpoint.smAndDown ? '' : 'NEW' }}
                        </v-btn>
                        <v-btn-toggle
                            v-if="projectId"
                            mandatory
                            borderless
                            style="height: 36px;"
                        >
                            <v-btn
                                @click="liveWO()"
                                style="font-size: 12px; height: 36px;"
                                class="px-3 py-1"
                            >
                                In Process
                            </v-btn>
                            <v-btn
                                @click="closedWO()"
                                style="font-size: 12px; height: 36px;"
                                class="px-3 py-1"
                            >
                                Closed
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.code`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="search"
                    dense
                    prepend-icon="mdi-filter"
                />
            </template>
            <template v-slot:[`header.project`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                    prepend-icon="mdi-filter"
                />
            </template>
            <template v-slot:[`header.status`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="statusToFilter"
                    dense
                    prepend-icon="mdi-filter"
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.createdBy`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ item.createdBy.name }}</p>
                </div>
            </template>
            <template v-slot:[`item.project`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.project.reference }} - {{ item.project.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.productionStart`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{
                            item.productionStart
                                ? formatDate(
                                      item.productionStart.seconds ||
                                          item.productionStart._seconds,
                                      'L'
                                  )
                                : ''
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.weight`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ calculateWeight(item).toFixed(2) }} kg
                    </p>
                </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <div class="d-flex justify-center">
                    <v-chip
                        :color="statusColor[item.status]"
                        class="white--text text-capitalize"
                    >
                        {{ item.status }}
                    </v-chip>
                </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center">
                    <v-btn small icon @click="openProcessWorkOrder(item)">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="
                            (item.status == 'inProgress' ||
                                item.status == 'rejected') &&
                                enableOptions &&
                                (item.projectManager == user.id ||
                                    item.createdBy.id == user.id)
                        "
                        small
                        icon
                        @click="openDeleteWorkOrder(item)"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="
                            enableOptions &&
                                (item.projectManager == user.id ||
                                    item.createdBy.id == user.id)
                        "
                        small
                        icon
                        @click="copyWorkOrder(item)"
                    >
                        <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            style="position: absolute; right: 0px; bottom: 0px"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <!--WORK ORDER FORM-->
        <v-dialog
            v-model="workOrder"
            :retain-focus="false"
            persistent
            width="600px"
        >
            <WorkOrderForm
                v-if="workOrder"
                :projectId="projectId"
                :project="project"
                @close="closeWorkOrderForm"
                @addWorkOrder="addWorkOrder"
            />
        </v-dialog>
        <!--PROCESS WORK ORDER-->
        <v-dialog
            v-model="processWorkOrder"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <ProcessWorkOrder
                v-if="processWorkOrder"
                :order="selectedOrder"
                @close="closeProcessWorkOrder"
            />
        </v-dialog>
        <!--Delete Work Order-->
        <v-dialog
            v-model="deleteWarning"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="deleteWarning">
                <v-card-title class="text-h5">Delete Work Order</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this work order?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeDeleteWorkOrder">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteWorkOrder"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--FIND WO-->
        <v-dialog
            v-model="findWO"
            :retain-focus="false"
            persistent
            max-width="600"
        >
            <FindWO v-if="findWO" @addWO="addWO" @close="findWO = false" />
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import { firestore } from '@/services/firebase'
import API from '@/services/api'
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import SocketioService from '@/services/websocket/socket.service.js'

export default {
    name: 'MainWorkOrders',
    props: {
        projectId: {
            type: String,
            default: () => undefined,
        },
        project: {
            type: Object,
            deafult: () => ({}),
        },
    },
    components: {
        WorkOrderForm: () =>
            import('@/components/WorkOrders/WorkOrderForm.vue'),
        ProcessWorkOrder: () =>
            import('@/components/WorkOrders/ProcessWorkOrder.vue'),
        FindWO: () => import('@/components/WorkOrders/FindWO.vue'),
    },
    data: () => ({
        deleteWarning: false,
        selectedOrder: undefined,
        processWorkOrder: false,
        workOrder: false,
        statusToFilter: undefined,
        projectToFilter: undefined,
        loading: false,
        workOrders: [],
        activateAlert: false,
        alertMessage: undefined,
        search: null,
        headers: [
            {
                text: 'ORDER',
                value: 'code',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CREATED BY',
                value: 'createdBy',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PROJECT',
                value: 'project',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PRODUCTION START',
                value: 'productionStart',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'center',
                sortable: false,
            },
            {
                text: 'WEIGHT',
                value: 'weight',
                align: 'center',
                sortable: false,
            },
            {
                text: 'STATUS',
                value: 'status',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        company: JSON.parse(localStorage.getItem('company')),
        statusColor: {
            sent: '#FF9800',
            approved: '#27BC4F',
            production: '#FFC000',
            rejected: 'red',
            closed: 'primary',
            inProgress: '#9E9FA0',
            finished: '#5cb85c',
        },
        listener: undefined,
        companyId: JSON.parse(localStorage.getItem('company')),
        enableOptions: true,
        user: {},
        resourceId: undefined,
        settingProcesses: undefined,
        showProcesses: false,
        processToShow: undefined,
        users: [],
        projects: [],
        dates: [],
        findWO: false,
    }),
    provide() {
        return {
            approveWO: this.approveWO,
        }
    },
    computed: {
        ...mapState(['notificationResource']),
        workOrdersFiltered() {
            this.openResource()
            let conditions = []
            if (this.search) {
                conditions.push(this.filterOrder)
            }
            if (this.projectToFilter) {
                conditions.push(this.filterProject)
            }
            if (this.statusToFilter) {
                conditions.push(this.filterStatus)
            }
            if (conditions.length > 0) {
                return this.workOrders.filter(order => {
                    return conditions.every(condition => {
                        return condition(order)
                    })
                })
            }
            this.sort()
            return this.workOrders
        },
    },
    watch: {
        notificationResource: function(resource) {
            if (resource) {
                this.resourceId = resource
                this.openResource()
                this.setNotificationResource(undefined)
            }
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.resourceId = this.$route.query.resourceId
            SocketioService.joinRoom(`${this.companyId}-WO`)
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            if (user.permissions.includes('watchProcesses')) {
                this.showProcesses = true
            } else if (user.process) {
                this.processToShow = user.process
                this.showProcesses = true
            }
            const {
                data: { settings },
            } = await API.getSettings()
            this.settingProcesses = settings.find(s => s.name == 'Processes')
            this.projects = await API.getLiteProjects({ all: true })
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            await this.liveWO()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    beforeDestroy() {
        if (this.listener) {
            this.listener()
            this.listener = null
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        ...mapActions(['setNotificationResource']),
        ...mapGetters(['getNotificationResource']),
        calculateWeight(workOrder) {
            try {
                if (!workOrder.items || workOrder.items?.length == 0) {
                    return 0
                }
                return workOrder.items.reduce(
                    (accumulator, item) =>
                        accumulator + item.mass * item.quantity,
                    0
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        closeDeleteWorkOrder() {
            this.selectedOrder = {}
            this.deleteWarning = false
        },
        openDeleteWorkOrder(item) {
            this.selectedOrder = _.cloneDeep(item)
            this.deleteWarning = true
        },
        async copyWorkOrder(item) {
            try {
                this.loading = true
                await API.copyWorkOrder(item)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async deleteWorkOrder() {
            try {
                this.loading = true
                await API.deleteWorkOrder(this.selectedOrder.id)
                this.closeDeleteWorkOrder()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        addWorkOrder() {
            this.activateAlert = true
            this.alertMessage = 'New Work Order Successfully Created'
        },
        openProcessWorkOrder(item) {
            this.selectedOrder = _.cloneDeep(item)
            this.processWorkOrder = true
        },
        closeProcessWorkOrder() {
            this.processWorkOrder = false
        },
        openWorkOrderForm() {
            this.workOrder = true
        },
        closeWorkOrderForm() {
            this.workOrder = false
        },
        filterOrder(order) {
            return order.code.toLowerCase().includes(this.search.toLowerCase())
        },
        filterStatus(order) {
            return order.status
                .toLowerCase()
                .includes(this.statusToFilter.toLowerCase())
        },
        filterProject(order) {
            return (
                order.project.name
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase()) ||
                order.project.reference
                    .toString()
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase())
            )
        },
        formatDate(seconds, format) {
            if (format == 'L') {
                return `${moment.unix(seconds).format('L')} ${moment
                    .unix(seconds)
                    .format('LT')}`
            } else {
                return `${moment.unix(seconds).format('YYYY-MM-DD')}`
            }
        },
        approveWO() {
            this.activateAlert = true
            this.alertMessage = 'WO Successfully Approved'
        },
        sort() {
            this.workOrders.sort(
                (a, b) =>
                    (b.createdOn.seconds || b.createdOn._seconds) -
                    (a.createdOn.seconds || a.createdOn._seconds)
            )
        },
        openResource() {
            if (this.resourceId) {
                const resource = this.workOrders.find(
                    w => w.id == this.resourceId
                )
                if (resource) {
                    this.resourceId = undefined
                    this.openProcessWorkOrder(resource)
                }
            }
        },
        setProcessesInfo(wo) {
            wo.processesInfo = []
            wo.items = wo.items ? wo.items : []
            wo.items.forEach(item => {
                if (item.processes) {
                    item.processesToShow = _.cloneDeep(item.processes)
                    if (this.processToShow) {
                        item.processesToShow = item.processesToShow.filter(
                            p => p == this.processToShow.id
                        )
                    }
                    item.processesToShow.forEach(process => {
                        let processInfo = wo.processesInfo.find(
                            info => info.process.id == process
                        )

                        if (!processInfo) {
                            let totalDelivered = item.deliveries
                                ? item.deliveries.reduce(
                                      (total, register) =>
                                          register.prevProcess.id == process
                                              ? total + register.qty
                                              : total,
                                      0
                                  )
                                : 0
                            let totalProduced = item.production
                                ? item.production.reduce(
                                      (total, register) =>
                                          register.process.id == process
                                              ? total + register.qty
                                              : total,
                                      0
                                  )
                                : 0
                            let processIndex = this.settingProcesses.processes.findIndex(
                                p => p.id == process
                            )

                            wo.processesInfo.push({
                                process: {
                                    id: this.settingProcesses.processes[
                                        processIndex
                                    ].id,
                                    name: this.settingProcesses.processes[
                                        processIndex
                                    ].name,
                                },
                                qty: item.quantity,
                                delivered: totalDelivered,
                                produced: totalProduced,
                            })
                        } else {
                            processInfo.qty += item.quantity
                            processInfo.delivered += item.deliveries
                                ? item.deliveries.reduce(
                                      (total, register) =>
                                          register.prevProcess.id == process
                                              ? total + register.qty
                                              : total,
                                      0
                                  )
                                : 0
                            processInfo.produced += item.production
                                ? item.production.reduce(
                                      (total, register) =>
                                          register.process.id == process
                                              ? total + register.qty
                                              : total,
                                      0
                                  )
                                : 0
                        }
                    })
                }
            })
            if (this.selectedOrder && this.selectedOrder.id == wo.id) {
                this.selectedOrder = _.cloneDeep(wo)
            }
        },
        async liveWO() {
            this.workOrders = []
            let query = firestore
                .collection('companies')
                .doc(this.companyId)
                .collection('workOrders')
            if (
                this.$router.currentRoute.name == 'project-work-orders' ||
                this.$router.currentRoute.name == 'archive-project-work-orders'
            ) {
                this.enableOptions = false
                query = query.where('projectId', '==', this.projectId)
            }
            this.listener = await query.onSnapshot(async docSnapshot => {
                for (const change of docSnapshot.docChanges()) {
                    const workOrder = Object.assign(change.doc.data(), {
                        id: change.doc.id,
                    })
                    ///
                    if (
                        this.$router.currentRoute.name !=
                            'project-work-orders' &&
                        this.$router.currentRoute.name !=
                            'archive-project-work-orders'
                    ) {
                        if (!this.user.permissions.includes('readAllWO')) {
                            if (
                                workOrder.createdBy != this.user.id &&
                                workOrder.projectManager != this.user.id
                            ) {
                                continue
                            }
                        }
                    }
                    const userIndex = this.users.findIndex(
                        u => u.id == workOrder.createdBy
                    )
                    if (userIndex >= 0) {
                        workOrder.createdBy = this.users[userIndex]
                    } else {
                        workOrder.createdBy = { id: workOrder.createdBy }
                        workOrder.createdBy.name = ''
                        this.users.push(workOrder.createdBy)
                    }
                    if (change.type === 'added') {
                        const projectIndex = this.projects.findIndex(
                            p => p.id == workOrder.projectId
                        )
                        if (projectIndex >= 0) {
                            workOrder.project = this.projects[projectIndex]
                        } else {
                            workOrder.project = await API.getProject(
                                workOrder.projectId
                            )
                            this.projects.push(workOrder.project)
                        }
                        this.workOrders.splice(0, 0, workOrder)
                    }
                    if (change.type === 'modified') {
                        const index = this.workOrders.findIndex(
                            r => r.id == workOrder.id
                        )
                        if (index >= 0) {
                            const projectIndex = this.projects.findIndex(
                                p => p.id == workOrder.projectId
                            )
                            if (projectIndex >= 0) {
                                workOrder.project = this.projects[projectIndex]
                            } else {
                                workOrder.project = await API.getProject(
                                    workOrder.projectId
                                )
                                this.projects.push(workOrder.project)
                            }
                            this.workOrders.splice(index, 1, workOrder)
                        }
                        if (
                            this.selectedOrder &&
                            this.selectedOrder.id == workOrder.id
                        ) {
                            this.selectedOrder = _.cloneDeep(workOrder)
                        }
                    }
                    if (change.type === 'removed') {
                        const index = this.workOrders.findIndex(
                            r => r.id == workOrder.id
                        )
                        if (index >= 0) {
                            this.workOrders.splice(index, 1)
                        }
                    }
                    if (this.showProcesses && workOrder.status == 'approved') {
                        this.setProcessesInfo(workOrder)
                    }
                }
            })
        },
        async closedWO() {
            try {
                this.listener = null
                this.workOrders = []
                this.loading = true
                this.dates[0] = this.formatDate(this.project.createdOn._seconds)
                let newDate = new Date()
                this.dates[1] = `${newDate.getFullYear()}-${newDate.getMonth() +
                    1}-${newDate.getDate()}`
                this.workOrders = await API.getClosedWorkOrders({
                    companyId: this.companyId,
                    startDate: this.dates[0],
                    endDate: this.dates[1],
                    projectId: this.projectId,
                })
                for (const WO of this.workOrders) {
                    const projectIndex = this.projects.findIndex(
                        p => p.id == WO.projectId
                    )
                    if (projectIndex >= 0) {
                        WO.project = this.projects[projectIndex]
                    } else {
                        WO.project = await API.getProject(WO.projectId)
                        this.projects.push(WO.project)
                    }
                }
                for (const WO of this.workOrders) {
                    const userIndex = this.users.findIndex(
                        p => p.id == WO.createdBy
                    )
                    if (userIndex >= 0) {
                        WO.createdBy = this.users[userIndex]
                    } else {
                        this.users.push({ id: WO.createdBy.id, name: '' })
                    }
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async addWO(wo) {
            try {
                this.loading = true
                const index = this.workOrders.findIndex(w => w.id == wo.id)
                if (index >= 0) {
                    this.openProcessWorkOrder(this.workOrders[index])
                } else {
                    wo.project = await API.getProject(wo.projectId)
                    wo.projectManager = this.users.find(
                        user => user.id == wo.project.projectManager
                    )
                    wo.creator = this.users.find(
                        user => user.id == wo.createdBy
                    )
                    wo.approvedBy = this.users.find(
                        user => user.id == wo.approvedBy
                    )
                    this.workOrders.splice(0, 0, wo)
                    this.openProcessWorkOrder(wo)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style>
.v-input .v-label {
    font-size: 12px;
}
</style>
